import Image from 'next/image'
import React from 'react'

import { Button } from '@wartek-id/button'
import { Icon } from '@wartek-id/icon'
import { Link } from '@wartek-id/link'
import { Text } from '@wartek-id/text'

import { PanelCollapsible } from 'components/PanelCollapsible'
import { Seo } from 'components/Seo'

const SECTION_FAQ_ID = 'faq-section'

export const LandingPage = () => {
  const handleScrollFaq = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()

    const faqSection = document.getElementById(SECTION_FAQ_ID)
    if (faqSection) {
      window.scrollTo({
        top: faqSection.offsetTop,
        behavior: 'smooth',
      })
    }
  }

  return (
    <div data-testid="landing-page">
      <Seo templateTitle="Bakal Calon Kepala Sekolah Dashboard" />

      <main className="min-h-screen bg-default">
        {/* Hero Section */}
        <section className="md:bg-white md:px-12 md:pb-6 md:pt-12">
          <div className="mx-auto flex max-w-screen-xl overflow-auto rounded-xl bg-surface-selected-default">
            <div className="w-full p-5 md:p-12">
              <Image
                alt="Kementerian Pendidikan, Kebudayaan, Riset, dan Teknologi"
                src="/images/logo-kemdikbud.png"
                height="72"
                width="72"
              />

              <div className="mt-12 max-w-xl md:mt-24">
                <Text as="h1" variant="headline-xs" className="mb-3">
                  Selamat Datang di Sistem Seleksi Kepala Sekolah dan Pengawas
                  Sekolah
                </Text>
                <Image
                  alt=""
                  width="356"
                  height="10"
                  src="/images/hero-underline.png"
                />
              </div>

              <div className="mt-20 flex flex-col space-y-4 md:flex-row md:space-x-4 md:space-y-0">
                <div>
                  <Text variant="body-sm">
                    Jika Anda anggota Dinas Pendidikan
                  </Text>
                  <Button
                    as="a"
                    className="mt-2"
                    color="blue"
                    fullWidth
                    href="/login"
                    size="lg"
                  >
                    Masuk sebagai Dinas Pendidikan
                  </Button>
                </div>
                <div>
                  <Text variant="body-sm">Jika Anda Guru</Text>
                  <Button
                    as="a"
                    className="mt-2"
                    color="white"
                    fullWidth
                    href={
                      process.env.NEXT_PUBLIC_BELAJAR_ID_HOME_URL ||
                      'https://guru.belajar.id/home'
                    }
                    size="lg"
                  >
                    Lihat Syarat Ikut Seleksi
                  </Button>
                </div>
              </div>

              <div className="items-top mt-20 flex space-x-2 text-icon-subdued">
                <Icon fontSize="sm" color="inherit">
                  desktop_windows
                </Icon>
                <Text className="ml-2" variant="body-sm" color="subdued">
                  Tampilan Sistem Seleksi paling baik dilihat lewat
                  Firefox/Chrome di komputer/laptop
                </Text>
              </div>

              <Button
                as="a"
                className="mt-9 animate-bounce"
                iconOnly
                size="sm"
                variant="ghost"
                onClick={handleScrollFaq}
                href={`#${SECTION_FAQ_ID}`}
              >
                <span className="sr-only">Ulir ke Bawah</span>
                <Icon color="inherit">arrow_downward</Icon>
              </Button>
            </div>

            <div className="hidden w-5/12 max-w-lg flex-shrink-0 lg:flex">
              <Image
                alt=""
                src="/images/hero-image.jpg"
                height={740}
                width={555}
                className="h-full object-cover"
              />
            </div>
          </div>
        </section>

        {/* FAQ Section */}
        <section className="px-5 md:px-12" id={SECTION_FAQ_ID}>
          <div className="mx-auto max-w-screen-xl pb-16 pt-11">
            <Text as="h2" variant="display-lg">
              Informasi Cara Masuk
            </Text>

            <div className="mt-9 space-y-4">
              <PanelCollapsible
                defaultOpen
                title="Bagaimana cara masuk ke website Seleksi Kepala Sekolah dan
                  Pengawas Sekolah?"
              >
                <ol className="list-inside list-decimal">
                  <Text as="li">
                    Klik tombol “Masuk dengan akun belajar.id” di halaman ini
                  </Text>
                  <Text as="li">
                    Pilih akun Google dengan alamat email berakhiran
                    @dinas.belajar.id atau @admin.belajar.id
                  </Text>
                </ol>
              </PanelCollapsible>

              <PanelCollapsible
                defaultOpen
                title="Bagaimana saya bisa mendapatkan akun belajar.id?"
              >
                <ol className="list-outside list-decimal pl-5">
                  <Text as="li">
                    Buka&nbsp;
                    <Link
                      className="!px-0"
                      href="https://belajar.id"
                      rel="noopener"
                      target="_blank"
                      variant="secondary"
                    >
                      https://belajar.id
                    </Link>
                  </Text>
                  <Text as="li">
                    Masukkan data diri Anda untuk memeriksa ketersediaan akun
                  </Text>
                  <Text as="li">
                    Klik “Kirim detail akun belajar.id ke email pribadi” untuk
                    mendapatkan identitas pengguna (berupa email) dan kata sandi
                    akun belajar.id
                  </Text>
                  <Text as="li">
                    Periksa kotak masuk email pribadi Anda dan aktifkan akun
                  </Text>
                </ol>
                <Text className="mt-6">
                  Untuk melihat rekam gambar beserta video tutorial cara
                  mendapatkannya, silakan kunjungi&nbsp;
                  <Link
                    className="!px-0"
                    href="https://belajar.id"
                    rel="noopener"
                    target="_blank"
                    variant="secondary"
                  >
                    website belajar.id
                  </Link>
                </Text>
              </PanelCollapsible>

              <PanelCollapsible
                defaultOpen
                title="Bagaimana cara mengaktifkan akun belajar.id?"
              >
                <ol className="list-outside list-decimal pl-5">
                  <Text as="li">
                    Buka&nbsp;
                    <Link
                      className="!px-0"
                      href="https://mail.google.com/"
                      rel="noopener noreferrer"
                      target="_blank"
                      variant="secondary"
                    >
                      mail.google.com
                    </Link>
                  </Text>
                  <Text as="li">
                    Masukkan email dan kata sandi belajar.id yang dikirimkan ke
                    email pribadi Anda
                  </Text>
                  <Text as="li">Ganti kata sandi Anda dengan yang baru</Text>
                  <Text as="li">
                    Jika Anda sudah masuk ke halaman Gmail, akun belajar.id Anda
                    sudah aktif
                  </Text>
                </ol>
                <Text className="mt-6">
                  Untuk melihat rekam gambar beserta video tutorial cara
                  mengaktifkannya, silakan kunjungi&nbsp;
                  <Link
                    className="!px-0"
                    href="https://belajar.id"
                    rel="noopener"
                    target="_blank"
                    variant="secondary"
                  >
                    website belajar.id
                  </Link>
                  .
                </Text>
              </PanelCollapsible>

              <PanelCollapsible
                defaultOpen
                title="Apa yang harus saya lakukan jika muncul halaman “Anda tidak memiliki akses”?"
              >
                <div className="flex flex-col items-start space-y-4 lg:flex-row lg:space-x-4 lg:space-y-0">
                  <div className="flex flex-shrink-0 rounded-lg border border-disabled px-8 pt-8">
                    <Image
                      alt="Kementerian Pendidikan, Kebudayaan, Riset, dan Teknologi"
                      src="/images/faq-no-acces-image.jpg"
                      height="230"
                      width="335"
                      className="object-cover"
                    />
                  </div>
                  <div className="lg:-order-1">
                    <Text>Halaman ini bisa muncul karena:</Text>
                    <ol className="list-outside list-decimal pl-5">
                      <Text as="li">
                        Anda bukan kepala sekolah atau dinas pendidikan daerah.
                        <br />
                        Hanya kepala sekolah dan dinas pendidikan daerah yang
                        bisa mengakses website Dasbor Pengangkatan KSPS ini.
                      </Text>
                      <Text as="li">
                        Anda menggunakan akun email pribadi.
                        <br />
                        Silakan masuk menggunakan akun Google yang berakhiran
                        @dinas.belajar.id atau @admin.belajar.id.
                      </Text>
                      <Text as="li">
                        Anda belum memiliki akun belajar.id.
                        <br />
                        Untuk mengajukan pembuatan akun dan kendala lainnya,
                        silakan menghubungi layanan bantuan (helpdesk) Dasbor
                        Pengangkatan KSPS melalui&nbsp;
                        <Link
                          className="!px-0"
                          href="https://ksps.zendesk.com/hc"
                          rel="noopener noreferrer"
                          target="_blank"
                          variant="secondary"
                        >
                          formulir ini
                        </Link>
                        .
                      </Text>
                      <Text as="li">
                        Anda belum mengaktifkan akun belajar.id.
                        <br />
                        Untuk melihat tutorial mengaktifkan akun, silakan
                        kunjungi&nbsp;
                        <Link
                          className="!px-0"
                          href="https://belajar.id"
                          rel="noopener"
                          target="_blank"
                          variant="secondary"
                        >
                          website belajar.id
                        </Link>
                        .
                      </Text>
                    </ol>
                  </div>
                </div>
              </PanelCollapsible>
            </div>

            <Button
              as="a"
              className="mt-3"
              color="white"
              fullWidth
              href="https://ksps.zendesk.com/hc"
              rel="noopener noreferrer"
              size="lg"
              target="_blank"
            >
              Lihat Informasi Selengkapnya
              <Icon className="ml-3">arrow_forward</Icon>
            </Button>
          </div>
        </section>
      </main>

      <footer className="bg-surface-foreground px-5 md:px-12">
        <div className="relative mx-auto max-w-screen-xl pb-9 pt-8">
          <div className="flex flex-col justify-between space-y-6 md:flex-row md:space-x-6 md:space-y-0">
            <div className="flex flex-col space-y-4 md:flex-row md:space-x-4 md:space-y-0">
              <div className="flex-shrink-0">
                <Image
                  alt=""
                  height="68"
                  src="/images/logo-kemdikbud.png"
                  width="68"
                />
              </div>

              <div className="space-y-4">
                <Text color="inverse" variant="action">
                  Kementerian
                  <br />
                  Pendidikan, Kebudayaan,
                  <br />
                  Riset, dan Teknologi
                </Text>

                <Text color="inverse">
                  Kompleks Kementerian Pendidikan dan Kebudayaan, Senayan,
                  Jakarta Pusat 10270
                </Text>
              </div>
            </div>

            <div className="flex flex-shrink-0 space-x-6">
              <Link
                className="!px-0"
                href="https://ksps.zendesk.com/hc"
                rel="noopener noreferrer"
                target="_blank"
                variant="white"
              >
                Syarat & Ketentuan
              </Link>
              <Link
                className="!px-0"
                href="https://ksps.zendesk.com/hc"
                rel="noopener noreferrer"
                target="_blank"
                variant="white"
              >
                Kebijakan Privasi
              </Link>
            </div>
          </div>

          <hr className="my-8 border-default" />

          <div className="flex flex-col items-start justify-between space-y-6">
            <Text className="" color="inverse" variant="body-sm">
              Hak Cipta Kementerian Pendidikan, Kebudayaan, Riset, dan Teknologi
              Republik Indonesia © 2022
            </Text>
            <Link
              color="white"
              className="bottom-0 right-0 inline-flex cursor-pointer rounded-t-lg border-x-2 border-t-4 border-primary !bg-white !px-6 !py-3 !no-underline md:!absolute"
              href="https://ksps.zendesk.com/hc"
              rel="noopener noreferrer"
              target="_blank"
            >
              <Icon color="informational" className="mr-2.5">
                help
              </Icon>
              <span className="whitespace-nowrap">Hubungi Kami</span>
            </Link>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default LandingPage
