import { useRouter, useSearchParams } from 'next/navigation'
import React from 'react'

import { useAuth } from 'utils/hooks/useAuth'

import LandingPage from 'app/LandingPage'

export const Index = () => {
  const router = useRouter()
  const query = useSearchParams()
  const { bcksUserSession } = useAuth()

  if (bcksUserSession) {
    if (query.get('from')) {
      router.replace(query.get('from') as string)
    } else {
      router.replace('/home')
    }

    return null
  }

  return <LandingPage />
}

export default Index

Index.Layout = ({ children }) => children
